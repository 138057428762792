/* CSS Switch */
input[type="checkbox"] {
    display: none;
}
input[type="checkbox"] + label {
    font-size: 1em;
    line-height: 1;
    width: 86px;
    height: 24px;
    background-color: #ced4da;
    background-image: none;
    border-radius: 2rem;
    padding: 0.16667rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    position: relative;
    font-weight: 600;
    transition: all 0.1s ease-in-out;
    margin: 0;
}
input[type="checkbox"] + label:before {
    color: #000;
    content: attr(data-off-label);
    display: block;
    font-family: inherit;
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    position: absolute;
    right: 1px;
    margin: 3px;
    top: -2px;
    text-align: center;
    min-width: 1.66667rem;
    overflow: hidden;
    transition: all 0.1s ease-in-out;
}
input[type="checkbox"] + label:after {
    content: '';
    position: absolute;
    left: 3px;
    background-color: #fff;
    box-shadow: none;
    border-radius: 2rem;
    height: 20px;
    width: 20px;
    top: 2px;
    transition: all 0.1s ease-in-out;
}
input[type="checkbox"]:checked + label {
    background-color: #34c38f;
}
input[type="checkbox"]:checked + label {
    background-color: #34c38f;
}
input[type="checkbox"]:checked + label:before {
    color: #fff;
    content: attr(data-on-label);
    right: auto;
    left: 3px;
}
input[type="checkbox"]:checked + label:after {
    left: 64px;
    background-color: #fff;
}
input[switch="bool"] + label {
    background-color: red;
}
input[switch="bool"] + label:before, input[switch="bool"]:checked + label:before, input[switch="default"]:checked + label:before {
    color: #fff;
}
input[switch="bool"]:checked + label {
    background-color: green;
}
input[switch="default"]:checked + label {
    background-color: #a2a2a2;
}
input[switch="primary"]:checked + label {
    background-color: red;
}
input[switch="success"]:checked + label {
    background-color: green;
}
input[switch="info"]:checked + label {
    background-color: blue;
}
input[switch="warning"]:checked + label {
    background-color: red;
}
input[switch="danger"]:checked + label {
    background-color: red;
}
input[switch="dark"]:checked + label {
    background-color: dark;
}
input[switch="dark"]:checked + label:before {
    color: #ccc;
}
.square-switch {
    margin-right: 7px;
}
.square-switch input[type="checkbox"] + label, .square-switch input[type="checkbox"] + label:after {
    border-radius: 4px;
}
