.CircularProgressbar {
    height: 100px !important;
}
.analytics-data svg.CircularProgressbar+div {
    margin-top: 0 !important;
    top: 50%;
    transform: translateY(-50%);
}
.eVPPvF {

    padding-right: 3px !important;
    
}

@media screen and (max-width: 575.98px) {
    .fs-14{
        font-size: 14px !important;
    }
}