.totalMegaPower{
    border: 1px solid black;
    border-radius: 5px;
}
.totalMegaPower p{
    padding: 18px 0px 0px 0px;
}
.calculatedAmount{
    border: 1px solid black;
    border-radius: 5px;
    gap: 5px;
}
.calculatedAmount p{
    padding: 18px 0px 0px 0px;
}
.heading{
    font-size: 14px;
}
.input{
    font-size: 10px !important;
}
.token{
    font-size: 10px;
}
