.serial_no {
    .card-body {
      .rdt_Table {
        .rdt_TableHead {
          .rdt_TableHeadRow {
            .rdt_TableCol {
              &:first-child {
                max-width: 100px;
                min-width: 100px;
              }
            }
          }
        }

        .rdt_TableBody {
          .rdt_TableRow {
            .rdt_TableCell {
              &:first-child {
                max-width: 100px;
                min-width: 100px;
              }
            }
          }
        }
      }
    }
  }